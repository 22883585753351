<template>
    <v-dialog v-model="opened" max-width="600">
        <template #activator="{ on, attrs }">
            <v-tooltip left :disabled="!tooltip">
                <template #activator="{ on: tooltip }">
                    <span style="height: 36px" v-on="tooltip">
                        <v-btn
                            color="primary"
                            :disabled="disabled"
                            v-bind="attrs"
                            data-cy="rollover-activator"
                            v-on="on"
                        >
                            Initiate Rollover
                        </v-btn>
                    </span>
                </template>
                <span>{{ tooltip }}</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pt-5 pl-5 pr-6">
                <h3 class="ma-0">Initiate Rollover</h3>
                <v-spacer />
                <v-icon color="rgba(192, 195, 207, 1)" @click="close"> fal fa-times </v-icon>
            </v-card-title>
            <v-card-text class="pl-5 pr-6" style="color: rgba(33, 37, 41, 1); font-size: 16px">
                You are attempting to initiate rollover. Would you like to continue?
            </v-card-text>
            <v-card-actions class="pb-5 px-5">
                <v-spacer />
                <v-btn text @click="close"> Cancel </v-btn>
                <v-btn color="primary" data-cy="start-rollover" @click="rollover">
                    Initiate Rollover
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    data: () => ({
        opened: false,
    }),
    computed: {
        ...mapGetters({
            lea: 'getDistrictLea',
            schoolYears: 'schoolYear/getItems',
            hasRole: 'auth/hasRole',
        }),
        schoolYear() {
            // Check if last year is available. This is the year we'll assume is being rolled over.
            return this.schoolYears.find((ele) => ele.schoolYear == new Date().getFullYear() - 1)
                ?.schoolYear
        },
        disabled() {
            return !this.hasRole('ROLE_ROLLOVER_START') || !this.schoolYear
        },
        tooltip() {
            if (!this.hasRole('ROLE_ROLLOVER_START')) {
                return 'Missing role "ROLE_ROLLOVER_START"'
            }
            if (!this.schoolYear) {
                return `${new Date().getFullYear() - 1} is not populated for a rollover`
            }

            return null
        },
    },
    async mounted() {
        this.fetchYears({ lea: this.lea })
    },
    methods: {
        ...mapActions({
            fetchYears: 'schoolYear/get',
            rolloverStaff: 'rollover/staff',
            rolloverTemplates: 'rollover/templates',
            rolloverExports: 'rollover/exports',
            reset: 'rollover/reset',
        }),
        close() {
            this.opened = false
        },
        rollover() {
            this.reset()
            this.rolloverStaff({
                lea: this.lea,
                schoolYear: this.schoolYear,
                executeRollover: true,
                rolloverAuthOnly: false,
            })
            this.rolloverTemplates({
                lea: this.lea,
                schoolYear: this.schoolYear,
                executeRollover: true,
            })
            this.rolloverExports({
                lea: this.lea,
                schoolYear: this.schoolYear,
                executeRollover: true,
            })
            this.close()
        },
    },
}
</script>
